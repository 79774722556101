export function formControlInit() {
    const $formControl = $(".form-control");

    $formControl.each(function () {
        const $t = $(this);
        checkFilled($t);
    });

    $(document).on('blur', '.form-control', function() {
        const $t = $(this);
        checkFilled($t);
    });
}

function checkFilled($el) {
    if ($el.val()) {
        $el.addClass("filled");
    } else {
        $el.removeClass("filled");
    }
}
