import "owl.carousel";

export function articlesCarousel() {
  const $c = $(".articles-carousel");

  if (!$c.length) return;

  $c.owlCarousel({
    nav: true,
    navText: [
      '<span class="if if-angle-left"></span>',
      '<span class="if if-angle-right"></span>'
    ],
    dots: false,
    autoHeight: true,
    loop: true,
    responsive: {
      0: {
        items: 1,
        autoWidth: false,
        margin: 0
      },
      768: {
        items: 2,
        autoWidth: false,
        margin: 30
      },
      1270: {
        items: 3,
        autoWidth: true,
        margin: 30
      }
    }
  });
}
