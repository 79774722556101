import {showPopup} from '../components/popup';

export function registerInit() {
    const $rf = $('.register-form');

    if(!$rf.length) return;

    $rf.on('submit', function (e) {
        e.preventDefault();
        //todo something

        showPopup($('#register-success'))
    });
}
