import $ from 'jquery'
global.$ = $
global.jQuery = $

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require('../src/site/app')

$(document).ready(function(){
  $('a[href^="#footnote"]').click(function(e){
    e.preventDefault();
    var elm = document.getElementById(e.currentTarget.hash.slice(1, e.currentTarget.hash.length));
    if(elm) {
      window.scrollTo(0, elm.offsetTop - 150);
    }
  })
  // 
  // var shops = [
  //   {shopId: 'sweetmed', showcaseId: '9075e2ea-4ad7-434f-9f95-5942c19acb4f'},
  //   {shopId: '027a2c64-a411-4ad0-ad2c-1b0d618fa7f7', showcaseId: '47f23b8a-62ab-4263-b8c5-9cee910ed121'}
  // ]
  // var shop = shops[Math.floor(Math.random()*shops.length)];
  // $('#purchase-installments input[name="shopId"]').val(shop.shopId);
  // $('#purchase-installments input[name="showcaseId"]').val(shop.showcaseId);
})
