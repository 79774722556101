import 'suggestions-jquery';
import {config} from '../config';

export function dadataInit() {
    const $dd = $('.dadata-init');

    if(!$dd.length) return;

    $dd.suggestions({
        token: config.dadataApi,
        type: 'ADDRESS',
        bounds: "city-settlement",
        onSelect: (suggestion) => {
            console.log(suggestion);
        }
    })
}
