
export function bannerCarousel() {
    const $c = $('.banner-carousel');

    if(!$c.length) return;

    $c.owlCarousel({
        items: 1,
        animateOut: 'fadeOut',
        nav: true,
        navText: ['<span class="if if-angle-left"></span>', '<span class="if if-angle-right"></span>'],
        dots: true,
        mouseDrag: false,
        autoplay: true,
        autoplayTimeout: 6000,
        autoplayHoverPause: true,
        loop: true
    });
}
