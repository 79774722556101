import 'owl.carousel';
import Cookies from 'js-cookie';

export function mainCarousel() {
    const $c = $('.main-carousel');

    if(!$c.length) return;

    $c.owlCarousel({
        items: 1,
        dots: true,
        nav: false,
        navText: ['<span class="if if-angle-left"></span>', '<span class="if if-angle-right"></span>'],
        animateOut: 'animate__slideOutDown',
        animateIn: 'animate__slideInLeft',
        mouseDrag: false,
        loop: true,
        autoplay: false,
        autoplayTimeout: 6000,
        autoplayHoverPause: true,
        onInitialized: function(e) {
          var index;
          if(Cookies.get('home_slide')) {
            index = Cookies.get('home_slide');
          } else {
            index = Math.floor(Math.random() * e.item.count);
          }
          setTimeout(function(){
            $('.main-carousel').trigger('to.owl.carousel', index);
            Cookies.set('home_slide', index, { expires: 7, path: '/' });
            setTimeout(function(){
              $('.main-carousel').trigger('play.owl.autoplay', 6000);
            }, 100);
            $('.main-carousel').show();
          }, 100 )

        }
    });
}
