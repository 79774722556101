import 'air-datepicker';

export function datePickerInit() {
    const $dp = $('.date-picker');
    if(!$dp.length) return;

    $dp.datepicker({
        maxDate: new Date(),
        dateFormat: 'dd.mm.yyyy',
        onShow: (inst) => {
            if (inst.$el.val()) {
              var [a,b,c] = inst.$el.val().split('.')
              inst.selectDate(new Date([c,b,a].join('-')))
            }
            inst.$el.addClass('date-picker-showed');
        },
        onHide: (inst)=> {
            inst.$el.removeClass('date-picker-showed');
        }
    });
}
