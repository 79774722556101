export function aboutDevice() {
    const $dot = $('.mobile-dot');
    const $info = $('.info-item');

    if(!$dot.length) return;

    if ($(window).width() < 1270) {
        $dot.on('click', function () {
            $info.fadeOut(300);
            $('.info-item-' + $(this).data('item')).fadeIn(300);
        });
    }
}
