
export function productLinesCarousel() {
    const $c = $('.product-lines-carousel');

    if (!$c.length) return;

    $c.owlCarousel({
        items: 1,
        dots: false,
        nav: true,
        navText: ['<span class="if if-angle-left"></span>', '<span class="if if-angle-right"></span>'],
        mouseDrag: false
    });
}
