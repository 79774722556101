
export function reviewsCarousel2() {
    const $c = $('.reviews-carousel-2');

    if(!$c.length) return;

    $c.owlCarousel({
        items: 1,
        dots: false,
        nav: true,
        navText: ['<span class="if if-angle-left"></span>', '<span class="if if-angle-right"></span>'],
        mouseDrag: false,
        loop: true
    });
}
