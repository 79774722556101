export function stickyHeader() {
    const $b = $('body');
    const $d = $(document);

    if($d.scrollTop() > 0) {
        $b.addClass('sticky-header');
    }

    $d.on('scroll', () => {
        if($d.scrollTop() > 0) {
            $b.addClass('sticky-header');
        } else {
            $b.removeClass('sticky-header');
        }
    })
}
