export function metrikaInit() {
  $(document).on('click', '.buy-btn-goal', function(){
    if(typeof yaCounter65312005 != 'undefined'){
      yaCounter65312005.reachGoal('nazhatie_knopki_kupit');
    }
    if(typeof ga != 'undefined'){
      ga('gtm4.send', 'event', 'buy_btn', 'click');
    }
  })

  $(document).on('click', '.appstore-goal', function(){
    if(typeof yaCounter65312005 != 'undefined'){
      yaCounter65312005.reachGoal('nazhatie_knopki_skachat_prilozhenie_s_App_Store');
    }
    if(typeof ga != 'undefined'){
      ga('gtm4.send', 'event', 'download_ios', 'click');
    }
  })

  $(document).on('click', '.googleplay-goal', function(){
    if(typeof yaCounter65312005 != 'undefined'){
      yaCounter65312005.reachGoal('nazhatie_knopki_skachat_prilozhenie_s_Google_Play');
    }
    if(typeof ga != 'undefined'){
      ga('gtm4.send', 'event', 'download_android', 'click');
    }
  })

  $(document).on('click', '.installment-btn-goal', function(){
    if(typeof yaCounter65312005 != 'undefined'){
      yaCounter65312005.reachGoal('klik_po_knopke_Kupit_v_rassrochku_i_Oformit');
    }
    if(typeof ga != 'undefined'){
      ga('gtm4.send', 'event', 'installment_btn', 'click');
    }
  })

  $(document).on('submit', '#purchase-installments form, #purchase-installments-inrange form', function(){
    if(typeof yaCounter65312005 != 'undefined'){
      yaCounter65312005.reachGoal('otpravka_formy_Kupit_v_rassrochku');
    }
    if(typeof ga != 'undefined'){
      ga('gtm4.send', 'event', 'installment', 'sendForm');
    }
  })

  $(document).on('click', '.request-link-goal', function(){
    if(typeof yaCounter65312005 != 'undefined'){
      yaCounter65312005.reachGoal('skachat_zayavlenie_v_servisnuyu_sluzhbu');
    }
    if(typeof ga != 'undefined'){
      ga('gtm4.send', 'event', 'request_link', 'click');
    }
  })

  $(document).on('click', '.book-link-goal', function(){
    if(typeof yaCounter65312005 != 'undefined'){
      yaCounter65312005.reachGoal('klik_po_knopke_Zabronirovat_v_apteke');
    }
    if(typeof ga != 'undefined'){
      ga('gtm4.send', 'event', 'book_link', 'click');
    }
  })

  $(document).on('click', '.signup-link-goal', function(){
    if(typeof yaCounter65312005 != 'undefined'){
      yaCounter65312005.reachGoal('klik_po_ssylke_Registraciya');
    }
    if(typeof ga != 'undefined'){
      ga('gtm4.send', 'event', 'signup_link', 'click');
    }
  })

  $(document).on('submit', '#new_user', function(){
    if(typeof yaCounter65312005 != 'undefined'){
      yaCounter65312005.reachGoal('zapolnenie_formy_Registracii');
    }
    if(typeof ga != 'undefined'){
      ga('gtm4.send', 'event', 'signup_form', 'fill');
    }
  })

  $(document).on('click', '.zdravcity-link-goal', function(){
    if(typeof yaCounter65312005 != 'undefined'){
      yaCounter65312005.reachGoal('klik_po_knopke_Zdravsiti');
    }
    if(typeof ga != 'undefined'){
      ga('gtm4.send', 'event', 'zdravcity_link', 'click');
    }
  })

  $(document).on('click', '.pandemic-link-goal', function(){
    if(typeof yaCounter65312005 != 'undefined'){
      yaCounter65312005.reachGoal('what-is-mno-click-buy');
    }
    if(typeof ga != 'undefined'){
      ga('gtm4.send', 'event', 'what-is-mno-click-buy', 'click');
    }
  })

  //

  $(document).on('click', '.goal-link', function(){
    var goal = $(this).data('goal-id');

    if(typeof goal == 'undefined') {
      return
    }

    if(typeof yaCounter65312005 != 'undefined'){
      yaCounter65312005.reachGoal(goal);
    }
    if(typeof ga != 'undefined'){
      ga('gtm4.send', 'event', goal, 'click');
    }
  })
}

export function purchaseReachGoal(product, point) {
  if(typeof yaCounter65312005 != 'undefined'){
    yaCounter65312005.reachGoal('buy_' + product + '_' + point);
  }
  if(typeof ga != 'undefined'){
    ga('gtm4.send', 'event', 'buy_' + product + '_' + point, 'click');
  }
}

window.purchaseReachGoal = purchaseReachGoal;
