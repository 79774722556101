import { config } from "../config";

export function initContactMap() {
  const $map = $("#contact-map");

  if (!$map.length) return;

  const lat = $map.data("lat");
  const lng = $map.data("lng");

  const pos = new google.maps.LatLng(lat, lng);

  const map = new google.maps.Map($map.get(0), {
    zoom: 13,
    center: pos,
    styles: config.mapStyle,
    disableDefaultUI: true,
    zoomControl: true,
    scaleControl: true
  });

  const marker = new google.maps.Marker({
    position: pos,
    icon: "/map-point.png",
    map: map,
    anchor: new google.maps.Point(-26.5, -20),
    scaledSide: new google.maps.Size(53, 40)
  });
}
