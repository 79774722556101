import {
  applicationCarousel,
  articlesCarousel,
  bannerCarousel,
  partnerCarousel,
  productLinesCarousel,
  reviewsCarousel,
  reviewsCarousel2,
  mainCarousel
} from "./components/carousels";
import { initVideo } from "./components/video";
import { initSidebar } from "./components/sidebar";
import { initPopup } from "./components/popup";
import { initContactMap } from "./modules/contact-map";
import { initAccordion } from "./components/accordion";
import { formControlInit } from "./components/form-control";
import { datePickerInit } from "./components/datepicker";
import { dadataInit } from "./components/dadata";
import { phoneMaskInit } from "./components/phone-mask";
import { registerInit } from "./modules/register";
import { changeAvatarInit } from "./components/change-avatar";
import { SearchMap } from "./modules/search-map";
import { burgerInit } from "./components/burger";
import { aboutDevice } from "./components/about-device";
import { stickyHeader } from './components/sticky-header';
import { cookies } from './components/cookies';
import { metrikaInit, purchaseReachGoal } from './components/metrika';
// import { sectionTab} from './components/section-tab';

import Cookies from 'js-cookie';

$(document).ready(init);

function init() {
  productLinesCarousel();
  reviewsCarousel();
  articlesCarousel();
  bannerCarousel();
  mainCarousel();
  initVideo();
  initSidebar();
  reviewsCarousel2();
  applicationCarousel();
  initPopup();
  partnerCarousel();
  initContactMap();
  initAccordion();
  datePickerInit();
  dadataInit();
  formControlInit();
  phoneMaskInit();
  registerInit();
  changeAvatarInit();
  burgerInit();
  aboutDevice();
  stickyHeader();
  cookies();
  metrikaInit();
  // sectionTab();

  new SearchMap();
}

$(document).ready(function() {
  $(".button-get-code").click(function(e) {
    e.preventDefault();
    if(($("#user_email").val()).length) {
      $.get(this.pathname + "?email=" + $("#user_email").val())
    }else{
      $("#user_email").focus()
    }
  });

  $(".trigger-validate").click(function() {
    if (
      !$(this)
        .parents("form")[0]
        .checkValidity()
    ) {
      event.preventDefault();
    }
  });

  if ($(".sidebar-navigation")[0] != undefined) {
    $(".h1, .section-title, h1, .h4").each(function(index, item) {
      $(".sidebar-navigation li ul.sub-navigation").append(
        "<li class='sub-link'><a href='" + ($(item).hasClass('hidden') ? $(item).attr('href') : '#') + "'>" + ($(item).data('title') ? $(item).data('title') : $(item).text()) + "</a></li>"
      );
    });

    $(".sidebar-navigation li ul.sub-navigation .sub-link a").click(function() {
      if ($(this).attr('href') != '#') {
        return
      }
      event.preventDefault();
      let items = $(".h1, .section-title, h1, .h4");
      for (let i = 0; i < items.length; i = i + 1) {
        if ($(items[i]).text() == $(this).text() || $(items[i]).data('title') == $(this).text()) {
          $("html, body").animate(
            {
              scrollTop: $(items[i]).offset().top - $('header').outerHeight() - 20
            },
            500
          );
        }
      }
    });
  }
});

$(document).on('yacounter65312005inited', function () {
  if(Cookies.get('home_slide')){
    if($('.main-carousel').length) {
      var index = parseInt(Cookies.get('home_slide'));
      ym(65312005, 'userParams', { 'slide': $('.main-carousel .owl-item:not(.cloned)').eq(index).find('.item').data('title') || index })

      if(typeof ga != 'undefined'){
        ga('gtm4.send', 'pageview', { 'dimension1': $('.main-carousel .owl-item:not(.cloned)').eq(index).find('.item').data('title') || index });
      }
    }
  }
});
