import 'inputmask/dist/min/jquery.inputmask.bundle.min.js';

export function phoneMaskInit() {
    const $pm = $('.phone-mask');

    if(!$pm.length) return;

    $pm.inputmask({
        mask: '+7 (999) 999-99-99',
        showMaskOnHover: false
    });
}
