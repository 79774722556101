import 'owl.carousel';

export function applicationCarousel() {
    const $c = $('.application-carousel');

    if(!$c.length) return;

    $c.owlCarousel({
        items: 1,
        dots: true,
        nav: true,
        navText: ['<span class="if if-angle-left"></span>', '<span class="if if-angle-right"></span>'],
        animateOut: 'fadeOut',
        mouseDrag: false,
        loop: true,
        autoplay: false
    });
}
