const $b = $("body");
const animationTime = 500;
let hiding = false;

function hidePopup($popup, $close) {
  hiding = true;

  $popup.addClass("fade-out");
  $b.addClass("hiding-popup");

  setTimeout(() => {
    $popup.removeClass("fade-out show");
    $b.removeClass("hiding-popup show-popup");
    $close.unbind("click.close-popup");
    $popup.unbind("click.outside");
    hiding = false;
  }, animationTime);
}

export function showPopup($popup) {
  const $close = $popup.find(".close");
  const $inner = $popup.find(".popup-inner");

  if ($('.popup.show').length) {
    const $curPopup = $('.popup.show');
    const $closeCurPopup = $('.popup.show .close');
    hidePopup($curPopup, $closeCurPopup);
  }

  $popup.bind("click.outside", function(e) {
    if (!$(e.target).closest($inner).length && !hiding) {
      hidePopup($popup, $close);
    }
  });

  $b.addClass("show-popup");
  $popup.addClass("show");

  $close.bind("click", function(e) {
    e.preventDefault();
    if (!hiding) hidePopup($popup, $close);
  });
}

window.showPopup = showPopup;

export function initPopup() {
  const $op = $(".open-popup");

  $op.on("click", function(e) {
    e.preventDefault();
    const href = $(this).attr("href");
    const $popup = $(href);

    showPopup($popup);
  });

  const hash = window.location.hash;
  if (hash && $(".popup" + hash).length) {
    const $popup = $(hash);

    showPopup($popup);
  }
}
