export function partnerCarousel() {
  const $c = $(".partner-carousel");

  if (!$c.length) return;

  $c.owlCarousel({
    nav: true,
    loop: true,
    navText: [
      '<span class="if if-angle-left"></span>',
      '<span class="if if-angle-right"></span>'
    ],
    responsive: {
      0: {
        items: 1,
        margin: 20
      },
      768: {
        items: 2,
        margin: 30
      },
      992: {
        items: 4,
        margin: 50
      }
    }
  });
}
