export function initAccordion() {
    const $a = $('.accordion-item');

    if(!$a.length) return;

    $a.find('.accordion-head').on('click', function (e) {
        const $p = $(this).parent();

        e.preventDefault();

        if(!$p.is('.active')) {
            $p.addClass('active');
            $p.find('.accordion-body').slideDown(300);
        } else {
            $p.removeClass('active');
            $p.find('.accordion-body').slideUp(300);
        }
    });
}
