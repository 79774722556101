import { config } from "../config";
import { showPopup } from "../components/popup";
import { getNoun } from './functions';

export class SearchMap {
  $map = $("#search-map");
  $shopList = $("#shop-list");
  $searchCity = $(".search-city");
  $pointsCount = $(".points-count");
  $searchTabs = $(".search-tabs");
  $searchMapPopup = $("#search-map-popup");
  $showAllBtn = $("#showAllPoints");
  map;
  markers = {};
  infoWindows = {};
  points;

  constructor() {
    if (this.$map.length) {
      this.createMap();
      this.dadata();
      this.initTabs();

      if (this.$showAllBtn.length) {
        var self = this
        this.$showAllBtn.on('click', function(){
          if(self.$showAllBtn.hasClass('active')) {
            self.clearMarkers();
            self.$shopList.html('');
            self.$searchTabs.removeClass('show');
            self.$showAllBtn.removeClass('active').text('Показать все точки продаж');
          } else {
            self.getPoints('all');
            self.$showAllBtn.addClass('active').text('Скрыть все точки продаж');
          }
        })
      }
    }
  }

  initTabs() {
    this.$searchTabs.find(".tab").on("click", e => {
      this.$searchTabs.find(".tab").removeClass("active");
      $(e.target).addClass("active");

      const type = $(e.target).data("type");

      this.changeTypeMarkers(type);
      this.createShopList(this.points, type);
    });
  }

  createMap() {
    const lat = 55.753651;
    const lng = 37.621714;
    if (typeof this.$map[0] != "undefined") {
      const pos = new google.maps.LatLng(lat, lng);

      this.map = new google.maps.Map(this.$map.get(0), {
        zoom: 10,
        center: pos,
        styles: config.mapStyle,
        disableDefaultUI: true,
        zoomControl: true,
        scaleControl: true
      });
    }
  }

  dadata() {
    this.$searchCity.suggestions({
      token: config.dadataApi,
      type: "ADDRESS",
      bounds: "city-settlement",
      geoLocation: false,
      onSelect: suggestion => {
        //todo start request for sail points
        this.getPoints(suggestion.data.city || suggestion.data.settlement);

        this.map.setCenter({
          lat: parseFloat(suggestion.data.geo_lat),
          lng: parseFloat(suggestion.data.geo_lon)
        });
      }
    });
  }

  getPoints(city) {
    $.getJSON("/sale_points.json?city=" + city, data => {
      if (data.message) {
        this.$searchMapPopup.find(".content").html(data.message);
        showPopup(this.$searchMapPopup);
        this.map.setZoom(7);
      } else {
        this.map.setZoom(10);
      }

      this.points = data["points"];
      // this.$pointsCount.find(".value").text(this.points.length + ' ' + getNoun(this.points.length, 'место', 'места', 'мест'));
      // $('.search-head .results').css('opacity', 1);
      if (!this.$searchTabs.is(".show")) this.$searchTabs.addClass("show");
      this.$searchTabs.find(".tab").removeClass("active");
      this.$searchTabs
        .find(".tab")
        .eq(0)
        .addClass("active");
      this.clearMarkers();
      this.createMarkers();
    });
  }

  createMarkers() {
    var bounds = new google.maps.LatLngBounds();

    for (let point of this.points) {
      let content = '<div class="info-window">';

      content += this.createHtmlList(point);

      content += "</div></div>";

      this.infoWindows[point.id] = new google.maps.InfoWindow({
        content: content,
        pixelOffset: new google.maps.Size(-11, 0)
      });

      this.markers[point.id] = new google.maps.Marker({
        position: new google.maps.LatLng(point.lat, point.lng),
        map: this.map,
        icon: config.pathMapIcon,
        anchor: new google.maps.Point(-26.5, -20),
        scaledSide: new google.maps.Size(53, 40)
      });

      this.markers[point.id].addListener("click", () => {
        for (let infoWindow in this.infoWindows) {
          this.infoWindows[infoWindow].close();
        }

        this.infoWindows[point.id].open(this.map, this.markers[point.id]);
      });

      bounds.extend(new google.maps.LatLng(point.lat, point.lng));
    }

    this.map.fitBounds(bounds);
    this.map.setZoom(Math.min(this.map.getZoom(), 13));

    this.createShopList(this.points, "all");
  }

  clearMarkers() {
    // console.log(this.markers);

    for (let marker in this.markers) {
      // console.log(this.markers[marker]);
      this.markers[marker].setMap(null);
    }

    this.markers = {};
  }

  createShopList(items, type) {
    this.$shopList.html("");

    for (let item of items) {
      if (item.en_point_type === type || type === "all") {
        let content =
          '<div class="col-12 col-sm-6 col-lg-4">' + '<div class="item">';

        content += this.createHtmlList(item);

        content += "</div></div>";

        this.$shopList.append(content);
      }
    }
  }

  createHtmlList(item) {
    let content = "";

    if (item.title) {
      content +=
        '<div class="contact">' +
        '<div class="if if-home"></div>' +
        "<span>Название:</span> " +
        item.title +
        "</div>";
    }

    if (item.address) {
      content +=
        '<div class="contact">' +
        '<div class="if if-contact-02"></div>' +
        "<span>Адрес:</span> " +
        item.address +
        "</div>";
    }

    if (item.metro) {
      content +=
        '<div class="contact">' +
        '<div class="if if-metro"></div>' +
        "<span>Метро:</span> " +
        item.metro +
        "</div>";
    }

    if (item.phone) {
      content +=
        '<div class="contact">' +
        '<div class="if if-contact-03"></div>' +
        "<span>Телефон:</span> " +
        item.phone +
        "</div>";
    }

    if (item.link) {
      content +=
        '<div class="contact">' +
        '<div class="if if-mouse"></div>' +
        '<span>Сайт:</span> <a href="' +
        item.link +
        '" target="_blank" rel="nofollow">' +
        item.link +
        "</a>" +
        "</div>";
    }

    if (item.description) {
      content += '<div class="contact">' + item.description + "</div>";
    }

    return content;
  }

  changeTypeMarkers(type) {
    for (let point of this.points) {
      if (point.en_point_type === type || type === "all") {
        this.markers[point.id].setVisible(true);
      } else {
        this.markers[point.id].setVisible(false);
      }
    }
  }
}
