import Cookies from 'js-cookie'

export function cookies() {
    const $c = $('.button-cookies');

    if(typeof Cookies.get('cookie-popup') == 'undefined') {
      $('.cookies-popup').slideDown(300);
    }

    $c.on('click', function () {
        $('.cookies-popup').slideUp(300);
        Cookies.set('cookie-popup', 1, { expires: 7, path: '/' })
    });
}
