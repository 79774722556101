const offset = 100;
const sidebarOffset = 20;
const $s = $('.second-sidebar');
const $sn = $s.length ? $s.find('.sidebar-navigation>ul>li.active .sub-navigation') : null;
const $b = $('body');
const $f = $('.site-footer');

export function initSidebar() {  if (!$s.length) return;

  onScroll();

  $(document).on('scroll', onScroll);

  $sn.find('.sub-link').on('click', onClick);

  if($(window).width() < 1500) {
      $s.addClass('hided');
  }

  $s.find('.burger').on('click', (e) => {
      e.preventDefault();
      $s.toggleClass('hided');
      onScroll();
  });
}

function onScroll() {
    const scrollPos = $(document).scrollTop();

    checkSidebarPosition(scrollPos);

    $sn.find('.sub-link').each(function () {
        const $t = $(this);
        const attr = $t.attr('href');

        if (!attr || attr === '#' || $t.is('.no-scroll')) {
            return;
        }

        const $el = $($t.attr('href'));

        if(!$el.length) {
            return;
        }

        if ($el.position().top <= scrollPos + offset && $el.position().top + $el.height() > scrollPos) {
            $sn.find('li.active').removeClass('active');
            $t.parent('li').addClass('active');
        } else {
            $t.parent('li').removeClass('active');
        }
    });
}

function onClick(e) {
    const $t = $(this);

    if ($t.is('.no-scroll')) {
        return;
    }

    e.preventDefault();
    const attr = $t.attr('href');


    if (!attr || attr === '#') {
        return;
    }

    const $target = $(attr);

    $(document).off('scroll');

    $sn.find('li.active').removeClass('active');
    $t.parent('li').addClass('active');

    $('html, body').stop().animate({
        'scrollTop': $target.offset().top
    }, 500, 'swing', function () {
        history.pushState({}, '', attr);

        $(document).on('scroll', onScroll);
    });
}

function checkSidebarPosition(scrollPos) {
    const height = $b.innerHeight() - $s.innerHeight() - $f.innerHeight() - sidebarOffset * 2;

    if (scrollPos >= 180 && scrollPos <= height) {
        $s.removeClass('fix-bottom').removeAttr('style');
        $s.addClass('fixed');
    } else if (scrollPos >= height) {
        $s.removeClass('fixed');
        $s.addClass('fix-bottom').css('bottom', $f.innerHeight() + sidebarOffset);
    } else {
        $s.removeClass('fixed');
        $s.removeClass('fix-bottom').removeAttr('style');
    }
}
