function toBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

function getFile($el) {
    const file = $el.get(0).files[0];

    toBase64(file).then(data => {
        $('.avatar-image img').attr('src', data);
    }).catch((e) => {
        console.error('Error: ', e);
        $('.avatar-image img').attr('src', '/assets/img/avatar-placeholder.jpg');
    });
}

export function changeAvatarInit() {
    const $ca = $('.change-avatar-label');

    if (!$ca.length) return;

    $ca.find('input[type="file"]').on('change', function (e) {
        getFile($(this));
    });
}
